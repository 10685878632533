@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family:"Rodin";
  src: url('../fonts/Rodin-M.otf') format("opentype");
}

* {
  font-family: "Rodin", san-serif;
}

html {
  min-height: 100%;
  font-size: 21px;
}

body {
  min-height: 100%;
}
